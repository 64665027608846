<template>
  <div class="row justify-content-center">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          {{ form.id ? "Job - " + form.job_unique_label : "Add new job" }}
        </div>
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-7 bg-light p-2">
                <div class="row">
                  <div class="col">
                    <label for="jon_no">Job No</label>
                    <input
                      disabled
                      class="form-control mb-2"
                      v-model="form.job_unique_label"
                    />
                  </div>
                  <div class="col">
                    <label for="invoice_no">Invoice No</label>
                    <input readonly v-model="form.invoice_no" class="form-control mb-2" @click="goToInvoice()" />  
                  </div>
                  <div class="col">
                    <label for="date">Date</label>
                    <input
                      v-if="form.id"
                      disabled
                      type="date"
                      v-model="form.date"
                      class="form-control mb-2"
                    />
                    <input
                      v-else
                      type="date"
                      v-model="form.date"
                      class="form-control mb-2"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label for="date">Customer</label>
                    <multiselect
                      v-model="form.customer"
                      tag-placeholder="Insert name"
                      placeholder="Search name"
                      label="name"
                      track-by="id"
                      :options="customers"
                      :multiple="false"
                      :taggable="true"
                      @tag="addCustomer"
                      @search-change="getCustomers"
                    ></multiselect>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div class="row ms-2 bg-dark">
                  <div class="col-12 p-2">
                    <div class="row">
                      <div class="col">
                        <label for="search_job" class="text-light">Search Job No</label>
                        <multiselect
                          v-model="form.job"
                          placeholder="Search job"
                          label="job_unique_label"
                          track-by="job_unique"
                          :options="jobs"
                          :multiple="false"
                          :taggable="false"
                          @search-change="getJobs"
                          @input="changeJob"
                          :disabled="form.job.id != null"
                        ></multiselect>
                      </div>
                      <div class="col-2" v-if="form.job.id != null">
                        <b-button class="mt-4" @click="clearJob()">Clear</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container bg-light mt-4">
            <div class="row">
              <div class="col-2">
                <label for="qty">Quantity</label>
                <input
                  v-model="form.quantity"
                  type="number"
                  name="qty"
                  id="qty"
                  class="form-control mb-2"
                  min="0"
                />
              </div>
              <div class="col-4">
                <label for="description">Description</label>
                <input
                  type="text"
                  name="description"
                  id="description"
                  class="form-control mb-2"
                  v-model="form.description"
                />
              </div>
              <div class="col">
                <label for="remark">Remarks</label>
                <input
                  v-model="form.remarks"
                  type="text"
                  name="remark"
                  id="remark"
                  class="form-control mb-2"
                />
              </div>
            </div>
          </div>

          <div class="container" v-if="form.id">
            <p class="mb-1 text-center fw-bolder text-uppercase mt-2 fs-5 text-primary">
              Job Details
            </p>
            <div class="row">
              <div class="col-6 bg-light">
                <div class="row mb-2">
                  <div class="col-6">
                    <label for="item_id">Item</label>
                    <div class="d-flex">
                      <multiselect
                        v-model="form.job_details.item"
                        placeholder="Search item"
                        label="item_label"
                        track-by="id"
                        :options="items"
                        :multiple="false"
                        :taggable="false"
                        @search-change="getItems"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <label for="job_qty">Job Qty</label>
                    <input
                      v-model="form.job_details.item.job_qty"
                      type="number"
                      name="job_qty"
                      id="job_qty"
                      class="form-control"
                      @focus="$event.target.select()"
                      min="0"
                    />
                  </div>
                  <div class="col">
                    <label for="qty">Qty</label>
                    <input
                      v-model="form.job_details.item.qty"
                      type="number"
                      name="qty"
                      id="qty"
                      class="form-control"
                      @focus="$event.target.select()"
                      @keyup="updateJobAmounts"
                      min="0"
                    />
                  </div>
                  <div class="col text-center">
                    <label for="qty_available">Qty Available</label>
                    <div class="text-danger text-center fw-bold fs-5">
                      {{ form.job_details.item.qty_available_label }}
                    </div>
                  </div>
                  <div class="col text-end">
                    <button
                      class="btn btn-primary w-75 ms-2 mt-3 text-uppercase"
                      @click="addJobRow"
                    >
                      Add
                    </button>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <label for="note">Note</label>
                    <textarea
                      class="form-control"
                      id="note"
                      rows="3"
                      v-model="form.job_details.note"
                    ></textarea>
                  </div>
                  <div class="col">
                    <label for="job_status">Job Status</label>
                    <select
                      id="job_status"
                      class="form-select mb-2"
                      aria-label="job_status"
                      v-model="form.job_details.job_status"
                    >
                      <option value="pending">Pending</option>
                      <option value="completed">Completed</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-6" style="max-height: 250px; overflow-y: scroll">
                <table class="table table-bordered bg-light">
                  <thead>
                    <tr>
                      <th scope="col">Job qty</th>
                      <th scope="col">Item</th>
                      <th scope="col">Qty</th>
                      <th scope="col">Date time</th>
                      <th v-if="form.role == 1"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="job in form.job_details.jobs" :key="job">
                      <td>{{ job["job_qty"] }}</td>
                      <td>{{ job["name"] }}</td>
                      <td>{{ job["qty"] }}</td>
                      <td>{{ job["date_time"] }}</td>
                      <td  v-if="form.role == 1" >
                        <b-button variant="danger" size="sm" @click="deleteJob(job)"
                          ><b-icon icon="trash-fill" aria-hidden="true"></b-icon></b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="container bg-light mt-5">
            <div class="row p-2">
              <div class="col bg-light">
                <div class="row">
                  <div class="col text-right">
                    <b-button
                      variant="dark"
                      size="sm"
                      @click="addPayment()"
                      v-if="form.job.id != null && form.balance > 0"
                      ><b-icon icon="currency-dollar" aria-hidden="true"></b-icon> Payments
                      </b-button
                    >
                    <b-button
                      variant="dark"
                      size="sm"
                      @click="addPayment()"
                      v-else-if="form.job.id != null"
                      ><b-icon icon="currency-dollar" aria-hidden="true"></b-icon> View Payments
                      </b-button
                    >
                    <b-button
                      variant="warning"
                      size="sm"
                      @click="payAll()"
                      v-if="form.job.id != null && form.balance > 0"
                      ><b-icon icon="credit-card" aria-hidden="true"></b-icon> Pay
                      all</b-button
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label for="payment_details">Payment Details</label>
                    <textarea
                      v-model="form.payment_details"
                      class="form-control"
                      id="payment_details"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="col ms-1 bg-light">
                <div class="row justify-content-between mb-2">
                  <div class="col-6">
                    <label for="amount" class="fw-bold">Amount</label>
                  </div>
                  <div class="col-6">
                    <input
                      type="number"
                      name="amount"
                      id="amount"
                      class="form-control fw-bold text-end"
                      placeholder="0.00"
                      v-model="form.amount"
                      @focus="$event.target.select()"
                      @keyup="updateAmounts"
                      :readonly="form.job.id != null"
                    />
                  </div>
                </div>
                <div class="row justify-content-between mb-2"  v-if="form.job.id != null">
                  <div class="col-6">
                    <label for="paid" class="fw-bold">Paid</label>
                  </div>
                  <div class="col-6">
                    <input
                      type="number"
                      name="paid"
                      id="paid"
                      class="form-control text-success fw-bold text-end" 
                      v-model="form.paid" 
                      readonly
                    />
                  </div>
                </div>
                <div class="row justify-content-between mb-2">
                  <div class="col-6">
                    <label for="advance" class="fw-bold">Advance</label>
                  </div>
                  <div class="col-6">
                    <input
                      type="number"
                      name="advance"
                      id="advance"
                      class="form-control text-danger fw-bold text-end"
                      placeholder="0.00"
                      v-model="form.advance"
                      @focus="$event.target.select()"
                      @keyup="updateAmounts"
                      :readonly="form.job.id != null"
                    />
                  </div>
                </div>
                <div class="row justify-content-between mb-2">
                  <div class="col-6">
                    <label for="balance" class="fw-bold">Balance</label>
                  </div>
                  <div class="col-6">
                    <input
                      type="number"
                      name="balance"
                      id="balance"
                      class="form-control fw-bold text-end text-danger"
                      placeholder="0.00"
                      v-model="form.balance"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="col ms-1 bg-dark">
                <div class="row p-3">
                  <div class="col">
                    <!--<button
                      class="btn btn-secondary w-100 ms-2 text-uppercase"
                      v-if="form.id"
                    >
                      Save & Print
                    </button>-->
                  </div>
                  <div class="col">
                    <button
                      @click="save"
                      class="btn btn-primary w-100 ms-2 text-uppercase"
                      :disabled="loading"
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div class="row p-3">
                  <div class="col">
                    <button
                      class="btn btn-danger w-100 ms-2 text-uppercase"
                      v-if="form.role == 1 && form.id"
                       @click="deleteThisJob()"
                    >
                      Delete
                    </button>
                  </div>
                  <div class="col">
                    <button
                      @click="clearJob()"
                      class="btn btn-secondary w-100 ms-2 text-uppercase"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notifications group="error" position="bottom right" />

    <payment-list-modal ref="payment_list_component"></payment-list-modal>

  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import moment from "moment";
import Multiselect from "vue-multiselect";
import PaymentList from "./modals/PaymentList.vue";
export default {
  components: {
    Multiselect,
    "payment-list-modal": PaymentList,
  },
  props: ["job_details"],
  data() {
    return {
      loading: false,
      form: {
        role: 0,
        job: {
          id: null,
          job_unique: null,
          job_unique_label: null,
        },
        id: null,
        job_unique_label: "JXXXX",
        invoice_no: null,
        date: moment(new Date()).format("YYYY-MM-DD"),
        customer: {
          id: null,
          name: null,
        },
        quantity: null,
        description: null,
        remarks: null,
        payment_details: null,
        amount: 0,
        advance: 0,
        balance: 0,
        item_id: null,
        job_details: {
          item: {
            id: null,
            name: null,
            qty_available: 0,
            qty_available_label: 0,
            job_qty: null,
            qty: 0,
          },
          note: null,
          jobs: [],
          job_status: "pending",
        },
      },
      customers: [],
      jobs: [],
      items: [],
    };
  },
  computed: {},
  created() {
    this.getCustomers();
    this.getJobs();
    this.getItems();
    if (typeof this.job_details !== "undefined") {
      this.form = this.job_details;
    }
  },
  methods: {
    deleteThisJob(){
this.$bvModal
        .msgBoxConfirm("Are you sure want to delete this job?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            axios.post("/j/delete-job", { id: this.form.invoice.id }).then((response) => {
              if (response.data.status) { 
                  document.location = '/jobs';
              }
            });
          }
        })
        .catch((err) => {
          // An error occurred
        });

    },
    payAll(){
      this.$bvModal
        .msgBoxConfirm("Are you sure want to complete payment of this Job?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            axios.post("/j/pay-all", { id : this.form.id}).then((response) => {
              if (response.data.status) {
                this.refresh();
              }
            });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    addPayment(){
      this.$refs["payment_list_component"].openList(
          this.form.id,
          this.form.job.id
        );
    },
    refresh(){
      axios.post("/j/getjob", { unique: this.form.job.job_unique }).then((response) => {
        if (response.data.data) {
          this.form = response.data.data;
        }
      });
    },
    goToInvoice(){
      document.location = '/invoice/'+this.form.invoice_no;

    },
    addCustomer(newTag) {
      const tag = {
        name: newTag,
        id: null,
      };
      this.customers.push(tag);
      this.form.customer = tag;
    },
    getCustomers(query) {
      axios.post("/j/customers", { search: query }).then((response) => {
        if (response.data.data) {
          this.customers = response.data.data;
        }
      });
    },
    getItems(query) {
      axios.post("/j/items", { search: query }).then((response) => {
        if (response.data.data) {
          this.items = response.data.data;
        }
      });
    },
    getJobs(query) {
      axios.post("/j/jobs", { search: query }).then((response) => {
        if (response.data.data) {
          this.jobs = response.data.data;
        }
      });
    },
    save() {
      this.loading = true;
      axios.post("/j/save/job", this.form).then((response) => {
        if (response.data.errors) {
          this.$notify({
            group: "error",
            type: "error",
            title: "Error",
            text: response.data.text,
          });
        } else {
          this.form = response.data.data;
          history.pushState(null, null, "/job/" + this.form.job.job_unique);
        }
      this.loading = false;
      });
    },
    updateAmounts() {
      this.form.balance = this.form.amount - this.form.advance;
    },
    changeJob() {
      document.location = "/job/" + this.form.job.job_unique;
    },
    updateJobAmounts() {
      this.form.job_details.item.qty_available_label =
        this.form.job_details.item.qty_available - this.form.job_details.item.qty;
    },
    deleteJob(job) {
      this.$bvModal
        .msgBoxConfirm("Are you sure want to delete this job?", {
          title: "Delete",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          if (value) {
            job["status"] = "delete";
            this.updateJobRows();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    addJobRow() {
      var errors = "";

      if (this.form.job_details.item.qty > this.form.job_details.item.qty_available) {
        errors += "<p>Qty is not valid, not enough available quantity to add</p>";
      }

      if (this.form.job_details.item.job_qty == null) {
        errors += "<p>Job quantity cannot be empty</p>";
      }

      if (this.form.job_details.item.qty_available == 0) {
        errors += "<p>Available qty is 0</p>";
      }

      if (this.form.job_details.item.id == null) {
        errors = "<p>Please select an item</p>";
      }

      if (errors != "") {
        this.$notify({
          group: "error",
          type: "error",
          title: "Error",
          text: errors,
        });
      } else {
        this.form.job_details.jobs.push(this.form.job_details.item);
        this.updateJobRows();
      }
    },
    updateJobRows() {
      axios.post("/j/save/job-item", this.form).then((response) => {
        if (response.data.errors) {
          this.$notify({
            group: "error",
            type: "error",
            title: "Error",
            text: response.data.text,
          });
        } else {
          this.form = response.data.data;
        }
      });
    },
    clearJob() {
      document.location = "/jobs";
    },
  },
  watch: {
    "form.amount": function(){
        this.form.amount = this.form.amount.toFixed(2);
        },
         "form.advance": function(){
        this.form.advance = this.form.advance.toFixed(2);
        },
         "form.balance": function(){
        this.form.balance = this.form.balance.toFixed(2);
        },
         "form.paid": function(){
        this.form.paid = this.form.paid.toFixed(2);
        }
  },
};
</script>
